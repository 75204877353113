import axios from "axios";
import setAuthToken from "../utils/setAuthToken";
import {jwtDecode} from "jwt-decode";
import {
  GET_ERRORS,
  SET_CURRENT_USER,
  USER_LOADING,
  GET_USERS,
  UPDATE_USER,
  ADD_USER,
  GET_USER_DETAILS,
} from "./types";
import { successAlert, errorAlert } from "./alertActions";
// Register User
export const registerUser = (userData) => (dispatch) => {
  axios
    .post("/api/users/register", userData)
    .then((res) => {
      successAlert("user added successfully!");
      dispatch({
        type: ADD_USER,
        payload: res.data,
      });
    })
    .catch((err) => {
      errorAlert("OOPS");
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

// Login - get user token
export const loginUser = (userData) => (dispatch) => {
  axios
    .post("/api/users/login", userData)
    .then((res) => {
      // Save to localStorage
      // Set token to localStorage
      const token = res.data;
      localStorage.setItem("jwtToken", token);
      // Set token to Auth header
      setAuthToken(token);
      // Decode token to get user data
      const decoded = jwtDecode(token);
      // Set current user
      dispatch(setCurrentUser(decoded));
    })
    .catch((err) => {
      errorAlert("OOPS");
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};
// Set logged in user
export const setCurrentUser = (decoded) => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded,
  };
};
// User loading
export const setUserLoading = () => {
  return {
    type: USER_LOADING,
  };
};
// Log user out
export const logoutUser = () => (dispatch) => {
  // Remove token from local storage
  localStorage.removeItem("jwtToken");
  // Remove auth header for future requests
  setAuthToken(false);
  // Set current user to empty object {} which will set isAuthenticated to false
  dispatch(setCurrentUser({}));
  dispatch({
    type: "RESET_APP",
    payload: null,
  })
};

export const getUsers = () => (dispatch) => {
  axios
    .get("/api/users")
    .then((res) => {
      dispatch({
        type: GET_USERS,
        payload: res.data,
      });
    })
    .catch((err) => {
      errorAlert("OOPS");
      dispatch({
        type: GET_USERS,
        payload: [],
      });
    });
};

export const getUser = (value) => (dispatch) => {
  axios
    .get("/api/users/ById/" + value)
    .then((res) => {
      dispatch({
        type: GET_USER_DETAILS,
        payload: res.data,
      });
    })
    .catch((err) => {
      errorAlert("OOPS");
      dispatch({
        type: GET_USER_DETAILS,
        payload: [],
      });
    });
};

export const updateUser = (value) => (dispatch) => {
  axios
    .put("/api/users", {
      id: value.id,
      post: value.post,
      actif: value.actif,
      username: value.username,
      mailEnregistrement: value.mailEnregistrement,
      mdpMailEnregistrement: value.mdpMailEnregistrement,
      mailBoiteVocal: value.mailBoiteVocal,
      mdpMailBoiteVocal: value.mdpMailBoiteVocal,
    })
    .then(function (response) {
      successAlert("user updated successfully!");
      dispatch({
        type: UPDATE_USER,
        payload: value,
      });
    })
    .catch(function (error) {
      errorAlert("OOPS");
      console.log(error);
    });
};
export const updatePassword = (value) => {
  axios
    .put("/api/users/updatePassword", value)
    .then(function (response) {
       successAlert("Mot de passe a été changé avec succès !");
    })
    .catch(function (error) {
      errorAlert(error);
    });
};



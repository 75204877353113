// import React from 'react';
// //import ReactDOM from 'react-dom';
// import { createRoot } from 'react-dom/client';

// import './index.css';
// // import 'mdb-react-ui-kit/dist/css/mdb.min.css'
// import App from './App';
// import * as serviceWorker from './serviceWorker';
// const container = document.getElementById('root');
// const root = createRoot(container);
// root.render(<App/>);

// //ReactDOM.render(<App />, document.getElementById('root'));

// // If you want your app to work offline and load faster, you can change
// // unregister() to register() below. Note this comes with some pitfalls.
// // Learn more about service workers: https://bit.ly/CRA-PWA
// // serviceWorker.unregister();
// serviceWorker.register();


import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
//import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
    <App />
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//serviceWorker.register();
serviceWorker.unregister();
//reportWebVitals();


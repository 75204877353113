 import React,{useEffect} from "react";
 import { connect } from "react-redux";
 import ReactApexChart from 'react-apexcharts';
import paginationFactory from "react-bootstrap-table2-paginator-react18-node20";
import BootstrapTable from "fad-react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter-react18-node20";
import isEmpty from "is-empty";
import { Tag, Divider, Row } from "antd";
import Paper from "@mui/material/Paper";
import { getCallsForDisplay } from "../../actions/dataActions";

function TvDisplay3(props) {  
useEffect(()=>{
    props.getCallsForDisplay();
},[])
  const rowStyle2 = (row, rowIndex) => {
    const style = {};

    if (row.ratio10 > 50 && row.ratio10 < 50) {
      style.backgroundColor = "#f07810";
      style.color = "white";
      style.fontWeight = "bold";
    } else if (row.ratioStatut > 50 && row.ratio10 < 50) {
      style.backgroundColor = "#e6e696";
      style.color = "gray";
      style.fontWeight = "bold";
    } else if (row.ratioStatut > 50 && row.ratio10 > 50) {
      style.backgroundColor = "#eb2632";
      style.color = "white";
      style.fontWeight = "bold";
    }

    // else {
    //   style.backgroundColor = "#E14D2A";
    //   style.color = "white";
    //   style.fontWeight = "bold";
    // }

    return style;
  };

  const msToTime = (duration) => {
    if (isNaN(duration)) return "--:--";
    var seconds = Math.floor((duration / 1000) % 60),
      minutes = Math.floor((duration / (1000 * 60)) % 60),
      hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

    hours = hours < 10 ? "0" + hours : hours;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    seconds = seconds < 10 ? "0" + seconds : seconds;

    return hours + ":" + minutes + ":" + seconds;
  };
  const dureeFormatter = (duree) => {
    return msToTime(parseInt(duree));
  };
  const ratioFormatter = (string) => {
    return !isEmpty(string) ? `${string} %` : "0 %";
  };

  const dureeMoyFormatter = (duree) => {
    return !isEmpty(duree) ? msToTime(parseInt(duree)) : "00:00:00";
  };
  const columns2 = [
    {
      dataField: "direction",
      text: " ",
      align: "center",
      sort: true,
      // formatter: rankFormatter,
      // formatExtraData: {
      //   out: 0,
      //   in: 1,
      // },
    },

    {
      text: "Opérateur",
      dataField: "userName",
      filter: textFilter(),
      sort: true,
    },
    {
      text: "Tous les appels",
      dataField: "nbrAppelTot",
      sort: true,
    },
    {
      text: "Appels manqués",
      dataField: "nbrAppelManque",
      sort: true,
    },
    {
      text: "Appels succées",
      dataField: "nbrAppelSucces",
      sort: true,
    },

    {
      text: "Durée tot",
      dataField: "dureeTotal",
      formatter: dureeFormatter,
      sort: true,
    },
    {
      text: "Durée moy",
      dataField: "dureeMoy",
      formatter: dureeMoyFormatter,
      sort: true,
    },
    {
      text: "Ratio Inj (<= 10s)",
      dataField: "ratio10",
      formatter: ratioFormatter,
      sort: true,
    },
    {
      text: "Ratio Inj (statut)",
      dataField: "ratioStatut",
      formatter: ratioFormatter,
      sort: true,
    },
  ];
  return (
    <div>
<Row>
    <Divider>
            <React.Fragment>
              {"nbreAppelSucces" in props.data.callsForDisplay ||
              "nbreAppelNonSucces" in props.data.callsForDisplay ? (
                <React.Fragment>
                  <Tag color="#108ee9">Nbr d'appel Total</Tag>
                  <Tag color="blue">
                    {" "}
                    {!isNaN(
                      props.data.callsForDisplay.nbreAppelSucces +
                        props.data.callsForDisplay.nbreAppelNonSucces
                    )
                      ? (
                          props.data.callsForDisplay.nbreAppelSucces +
                          props.data.callsForDisplay.nbreAppelNonSucces
                        ).toString()
                      : null}
                  </Tag>
                </React.Fragment>
              ) : null}
              {"nbreAppelSucces" in props.data.callsForDisplay ? (
                <React.Fragment>
                  <Tag color="#90B77D">Nbr d'appel succes</Tag>
                  <Tag color="success">
                    {props.data.callsForDisplay.nbreAppelSucces}
                  </Tag>
                </React.Fragment>
              ) : null}
              {"nbreAppelNonSucces" in props.data.callsForDisplay ? (
                <React.Fragment>
                  <Tag color="#EB1D36">Nbr d'appel manque</Tag>
                  <Tag color="red">
                    {props.data.callsForDisplay.nbreAppelNonSucces}
                  </Tag>
                </React.Fragment>
              ) : null}
              {"ratio" in props.data.callsForDisplay ? (
                <React.Fragment>
                  <Tag color="#9E7676">Ratio Injoignable {"<= 10s"}</Tag>
                  <Tag color="volcano">
                    {props.data.callsForDisplay.ratio} %
                  </Tag>
                </React.Fragment>
              ) : null}

{props.data.callsForDisplay.ratioStatutTot ? (
                <React.Fragment>
                  <Tag color="#f5c7f7">Ratio Injoignable (Statut)</Tag>
                  <Tag color="purple">
                    {
                      parseInt(props.data.callsForDisplay.ratioStatutTot) 
                    } %
                  </Tag>
                </React.Fragment>
              ) : null}

              {props.data.callsForDisplay.dureeTotal &&
              props.data.callsForDisplay.nbreAppelSucces ? (
                <React.Fragment>
                  <Tag color="#FFAE6D">Durée moy d'appel</Tag>
                  <Tag color="gold">
                    {msToTime(
                      parseInt(
                        props.data.callsForDisplay.dureeTotal /
                        props.data.callsForDisplay.nbreAppelSucces
                      )
                    )}
                  </Tag>
                </React.Fragment>
              ) : null}
              {props.data.callsForDisplay.dureeTotal ? (
                <React.Fragment>
                  <Tag color="#f5587b">Durée tot d'appel</Tag>
                  <Tag color="magenta">
                    {msToTime(
                      parseInt(props.data.callsForDisplay.dureeTotal)
                    )}
                  </Tag>
                </React.Fragment>
              ) : null}
              
            </React.Fragment>
          </Divider>{" "}
        </Row> 

      <Paper elevation={2}>
        {" "}
        <br />
        <ToolkitProvider
          keyField="_id"
          data={props.data.callsForDisplay.listtotCallsOp}
          search
          columns={columns2}
        >
          {(props) => (
            <div>
              <div align="center">
                <Tag color="#f07810">Ratio inj (10s) superieur à 50%</Tag>
                <Tag color="#e6e696">Ratio inj (statut) superieur à 50%</Tag>
                <Tag color="#eb2632">
                  Ratio inj ( 10s et statut) superieur à 50%
                </Tag>
              </div>
              <br />
              <BootstrapTable
                {...props.baseProps}
                // pagination={paginationFactory(options)}
                filter={filterFactory()}
                filterPosition="top"
                condensed
                noDataIndication="Pas d'appels"
                // onDataSizeChange={handleDataChange}
                rowStyle={rowStyle2}
                bordered={false}
                striped
              />
            </div>
          )}
        </ToolkitProvider>
        {/* </Col> */}
      </Paper>
    </div>
  );
}


const mapStateToProps = (state) => ({
    auth: state.auth,
    data: state.data,
    contact: state.contact,
  });
  
  export default connect(mapStateToProps, {
    getCallsForDisplay,
  })(TvDisplay3);

import react, { useEffect, useState } from "react";
import { connect } from "react-redux";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import HelpIcon from "@mui/icons-material/Help";
//import { Table } from "antd";
import Rating from "@mui/material/Rating";
import Typography from '@mui/material/Typography';

import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { AlertTwoTone, TabletTwoTone, GlobalOutlined } from "@ant-design/icons";
import { getProspectionCommercialeTV4 } from "../../actions/dataActions";

function TvDisplay4(props) {
  const [rowsTv4, setRowsTv4] = useState([]);
  const [search, setSearch] = useState("");
  //var rowsTv4 =[];

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#ececec",
      //  backgroundColor: " #feffdf",
      fontWeight: "bolder",
      //backgroundColor: theme.palette.common.black,
      //color: theme.palette.common.white,

      color: "black",
      width:"300px",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
      height:"70px",
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  function createData(name, week, month, year) {
    return { name, week, month, year };
    // console.log(name,week,month,year)
  }

  //console.log("===>>>>>>> props.data.salesForDisplayTv4", props.data.salesForDisplayTv4)

  const rowsd = [
    createData(
      "opérateur 1",
      { weekSales: { alarme: "32", mobile: "3", internet: "6" } },
      { monthSales: { alarme: "32", mobile: "3", internet: "6" } },
      { yearSales: { alarme: "32", mobile: "3", internet: "6" } }
    ),
    createData(
      "opérateur 2",
      { weekSales: { alarme: "32", mobile: "3", internet: "6" } },
      { monthSales: { alarme: "32", mobile: "3", internet: "6" } },
      { yearSales: { alarme: "32", mobile: "3", internet: "6" } }
    ),
    createData(
      "opérateur 3",
      { weekSales: { alarme: "32", mobile: "3", internet: "6" } },
      { monthSales: { alarme: "32", mobile: "3", internet: "6" } },
      { yearSales: { alarme: "32", mobile: "3", internet: "6" } }
    ),
    createData(
      "opérateur 4",
      { weekSales: { alarme: "32", mobile: "3", internet: "6" } },
      { monthSales: { alarme: "32", mobile: "3", internet: "6" } },
      { yearSales: { alarme: "32", mobile: "3", internet: "6" } }
    ),
    createData(
      "opérateur 5",
      { weekSales: { alarme: "32", mobile: "3", internet: "6" } },
      { monthSales: { alarme: "32", mobile: "3", internet: "6" } },
      { yearSales: { alarme: "32", mobile: "3", internet: "6" } }
    ),
    createData(
      "opérateur 6",
      { weekSales: { alarme: "32", mobile: "3", internet: "6" } },
      { monthSales: { alarme: "32", mobile: "3", internet: "6" } },
      { yearSales: { alarme: "32", mobile: "3", internet: "6" } }
    ),
    createData(
      "opérateur 7",
      { weekSales: { alarme: "32", mobile: "3", internet: "6" } },
      { monthSales: { alarme: "32", mobile: "3", internet: "6" } },
      { yearSales: { alarme: "32", mobile: "3", internet: "6" } }
    ),
    createData(
      "opérateur 8",
      { weekSales: { alarme: "32", mobile: "3", internet: "6" } },
      { monthSales: { alarme: "32", mobile: "3", internet: "6" } },
      { yearSales: { alarme: "32", mobile: "3", internet: "6" } }
    ),
    createData(
      "opérateur 9",
      { weekSales: { alarme: "32", mobile: "3", internet: "6" } },
      { monthSales: { alarme: "32", mobile: "3", internet: "6" } },
      { yearSales: { alarme: "32", mobile: "3", internet: "6" } }
    ),
    createData(
      "opérateur 10",
      { weekSales: { alarme: "32", mobile: "3", internet: "6" } },
      { monthSales: { alarme: "32", mobile: "3", internet: "6" } },
      { yearSales: { alarme: "32", mobile: "3", internet: "6" } }
    ),
    createData(
      "opérateur 11",
      { weekSales: { alarme: "32", mobile: "3", internet: "6" } },
      { monthSales: { alarme: "32", mobile: "3", internet: "6" } },
      { yearSales: { alarme: "32", mobile: "3", internet: "6" } }
    ),
    createData(
      "opérateur 12",
      { weekSales: { alarme: "32", mobile: "3", internet: "6" } },
      { monthSales: { alarme: "32", mobile: "3", internet: "6" } },
      { yearSales: { alarme: "32", mobile: "3", internet: "6" } }
    ),
    createData(
      "opérateur 13",
      { weekSales: { alarme: "32", mobile: "3", internet: "6" } },
      { monthSales: { alarme: "32", mobile: "3", internet: "6" } },
      { yearSales: { alarme: "32", mobile: "3", internet: "6" } }
    ),
  ];

  useEffect(() => {
    props.getProspectionCommercialeTV4();
  }, []);

  //rowsTv4 = props.data.salesForDisplayTv4?.map(el=>createData(el.operateur,{weekSales:el.weekSales},{monthSales:el.monthSales},{yearSales:el.yearSales}))
  // console.log("&&&&&&&&&&&&&&&&&&",rowsTv4)

  // const handleSearch = (e) => {
  //   setSearch(e.target.value);
  //   setRowsTv4(
  //     props.data.salesForDisplayTv4
  //       .filter((el) =>
  //         el.operateur.toLowerCase().includes(e.target.value.toLowerCase())
  //       )
  //       .map((el) =>
  //         createData(
  //           el.operateur,
  //           { weekSales: el.weekSales },
  //           { monthSales: el.monthSales },
  //           { yearSales: el.yearSales }
  //         )
  //       )
  //   );
  // };

  useEffect(() => {
    setRowsTv4(
      props.data.salesForDisplayTv4?.map((el) =>
        createData(
          el.operateur,
          { weekSales: el.weekSales },
          { monthSales: el.monthSales },
          { yearSales: el.yearSales }
        )
      )
    );
  }, [props.data]);

  useEffect(() => {
    setRowsTv4(
      props.data.salesForDisplayTv4
        .filter((el) =>
          el.operateur.toLowerCase().includes(search.toLowerCase())
        )
        ?.map((el) =>
          createData(
            el.operateur,
            { weekSales: el.weekSales },
            { monthSales: el.monthSales },
            { yearSales: el.yearSales }
          )
        )
    );
  }, [search, setSearch]);

  return (
    <div>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar
          position="static"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            background: "#ffc93c",
            // background:"gray",
            height: "70px",
            //  height: "90px",
            flexDirection: "row",
            // borderBlockStart:"20px #ffc93c solid"
            marginBottom: "15px",
          }}
        >
          <Toolbar
            style={{
              marginLeft: "20px",
            }}
          >
            <SearchIcon id="SearchIconTvDisplay4" />
            <TextField
              // id="standard-basic"
              id="outlined-size-small"
              label="Rechercher..."
              variant="filled"
              size="small"
              style={{
                width: "250px",
                background: "white",
                // height:"40px"
              }}
              onChange={(e) => setSearch(e.target.value)}
            />
          </Toolbar>
          {/* <h3 style={{
            fontWeight:"bolder", 
            color:"#757a79",
            //color:"#757a79",
            }}>
          Statistiques de Ventes par Opérateur
          </h3> */}


<Typography variant="h5" style={{fontWeight:"bolder", color:"#757a79"}}>
Statistiques de Ventes par Opérateur
</Typography>


          <HelpIcon
            style={{
              marginRight: "35px",
              color: "#fafafa",
              cursor: "pointer",
            }}
          />
        </AppBar>
      </Box>
      <div>
        {/* <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <h3
            style={{
              marginLeft: "20px",
              marginTop: "20px",
            }}
          >
            Abbassi Chayma
          </h3>
          <Rating
            ame="read-only"
            value={3.5}
            // precision={0.5}
            style={{
              marginLeft: "15px",
              marginTop: "10px",
            }}
            readOnly
          />
        </div> */}
        <div className="tablesTvDisplay4">
          {/* tab alarme */}
          <div style={{ flex: 1 }}>
            <TableContainer component={Paper}>
              <Table aria-label="customized table">
                {/* <Table sx={{ minWidth: 700 }} aria-label="customized table"> */}
                <TableHead>
                  <TableRow>
                    <StyledTableCell
                      style={{
                        background: `repeating-linear-gradient(
                                     45deg,
                                     #ffe79a,
                                     gray 5px,
                                     #fff 5px,
                                     #fff 10px
                                   )`,
                      }}
                    ></StyledTableCell>
                    <StyledTableCell
                      align="center"
                      style={{
                        color: "#2a2438",
                        fontWeight: "bolder",
                        fontSize: "20px",
                      }}
                    >
                      Semaine
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-around",
                          color: "red",
                        }}
                      >
                        <AlertTwoTone style={{ fontSize: "15px" ,marginTop:"10px"}} />

                        <TabletTwoTone style={{ fontSize: "15px" ,marginTop:"10px" }} />

                        <GlobalOutlined
                          style={{ color: "#1890ff", fontSize: "15px" ,marginTop:"10px" }}
                        />
                      </div>
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      style={{
                        color: "#2a2438",
                        fontWeight: "bolder",
                        fontSize: "20px",
                      }}
                    >
                      Mois
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-around",
                          color: "red",
                        }}
                      >
                        <AlertTwoTone style={{ fontSize: "15px" ,marginTop:"10px" }} />

                        <TabletTwoTone style={{ fontSize: "15px" ,marginTop:"10px"}} />

                        <GlobalOutlined
                          style={{ color: "#1890ff", fontSize: "15px" ,marginTop:"10px"}}
                        />
                      </div>
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      style={{
                        color: "#2a2438",
                        fontWeight: "bolder",
                        fontSize: "20px",
                      }}
                    >
                      Année
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-around",
                        }}
                      >
                        <AlertTwoTone style={{ fontSize: "15px" ,marginTop:"10px"}} />

                        <TabletTwoTone style={{ fontSize: "15px" ,marginTop:"10px"}} />

                        <GlobalOutlined
                          style={{ color: "#1890ff", fontSize: "15px" ,marginTop:"10px"}}
                        />
                      </div>
                    </StyledTableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {rowsTv4.map((row) => (
                    <StyledTableRow key={row.name}>
                      <StyledTableCell
                        component="th"
                        scope="row"
                        align="left"
                      >
                        {row.name}
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        style={{
                          background: "#fcf4d9",
                          color: "black",
                          fontWeight: "normal",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-around",
                            width: "100%",
                            height: "100%",
                          }}
                        >
                          <div>{row.week.weekSales.alarme}</div>
                          <div> {row.week.weekSales.mobile}</div>
                          <div> {row.week.weekSales.internet}</div>
                        </div>
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        style={{
                          background: "#fdffcd",
                          color: "black",
                          fontWeight: "bolder",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-around",
                            width: "100%",
                            height: "100%",
                          }}
                        >
                          <div>{row.month.monthSales.alarme}</div>
                          <div>{row.month.monthSales.mobile} </div>
                          <div>{row.month.monthSales.internet} </div>
                        </div>
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        style={{
                          background: "#ffebbb",
                          color: "black",
                          fontWeight: "bold",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-around",
                            width: "100%",
                            height: "100%",
                          }}
                        >
                          <div> {row.year.yearSales.alarme}</div>
                          <div>{row.year.yearSales.mobile}</div>
                          <div> {row.year.yearSales.internet}</div>
                        </div>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          {/* tab alarme */}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  data: state.data,
  contact: state.contact,
});

export default connect(mapStateToProps, { getProspectionCommercialeTV4 })(TvDisplay4);

import React, { Component } from "react";
import { connect } from "react-redux";
import { BrowserRouter as Router, Link } from "react-router-dom";
import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar";
import DialerSipIcon from "@mui/icons-material/DialerSip";
import Paper from "@mui/material/Paper";
import { Tag, Divider, Row } from "antd";

import filterFactory, { textFilter } from "react-bootstrap-table2-filter-react18-node20";
import { searchContactFiche } from "../../actions/contactActions";
import paginationFactory from "react-bootstrap-table2-paginator-react18-node20";
import BootstrapTable from "fad-react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import {
  FiPhoneOutgoing,
  FiPhoneIncoming,
  FiPhoneMissed,
} from "react-icons/fi";

import { getCallsForDisplay } from "../../actions/dataActions";

import isEmpty from "is-empty";
import { Bar } from "@ant-design/plots";

const formatHour = (string) => {
  if (!string) return "-";
  var options = { hour: "numeric", minute: "numeric", second: "numeric" };
  return new Date(string).toLocaleTimeString([], options);
};
const compareTime = (st1, st2) => {
  var str1 = st1;
  var str2 = st2;

  str1 = str1.split(":");
  str2 = str2.split(":");

  const totalSeconds1 = parseInt(str1[0] * 3600 + str1[1] * 60 + str1[0]);
  const totalSeconds2 = parseInt(str2[0] * 3600 + str2[1] * 60 + str2[0]);
  if (totalSeconds1 > totalSeconds2) {
    return -1;
  } else return 1;
};
class TvDisplay extends Component {
  constructor(props) {
    super(props);
    this.handleDate = this.handleDate.bind(this);
    this.handleDataChange = this.handleDataChange.bind(this);
    this.handleHeureSelection = this.handleHeureSelection.bind(this);
    this.handleStatutAppelSelection =
      this.handleStatutAppelSelection.bind(this);
    this.handleSecteurActiviteSelection =
      this.handleSecteurActiviteSelection.bind(this);
    this.handleCategorieActiviteSelection =
      this.handleCategorieActiviteSelection.bind(this);

    this.handleTypeRecherche = this.handleTypeRecherche.bind(this);
    this.state = {
      typeRecherche: "op",
      typeFiltreActivite: "cat",
      categorieActivite: [],
      secteursActivite: [],
      statutAppel: [],
      searchType: { type: "day", change: false },
      rowCount: 0,
      user: { userId: "", change: false },
      isOpen: false,

      showCards: false,
      dateDebut: {
        date: new Date().setDate(new Date().getDate()),
        change: false,
      },
      dateFin: { date: new Date(), change: false },
      dureeMin: { duree: "00:20", change: false },
      dureeMax: { duree: "99:99", change: false },
      heureDeb: "00:00:00",
      heureFin: "23:59:59",
    };
  }
  async componentDidMount() {
    this.props.getCallsForDisplay();
  }
  handleTypeRecherche(args) {
    this.setState({
      typeRecherche: args,
    });
  }
  handleHeureSelection(args) {
    if (args !== null) {
      this.setState({
        heureDeb: formatHour(args[0].$d),
        heureFin: formatHour(args[1].$d),
      });
    } else {
      this.setState({
        heureDeb: "00:00:00",
        heureFin: "23:59:59",
      });
    }
  }
  handleStatutAppelSelection(args) {
    if (args.name === "selectedItems") {
      this.setState({
        statutAppel: args.value,
      });
    }
  }
  handleCategorieActiviteSelection(args) {
    if (args.name === "selectedItems") {
      this.setState({
        categorieActivite: args.value,
        secteursActivite: [],
      });
    }
  }
  handleSecteurActiviteSelection(args) {
    if (args.name === "selectedItems") {
      this.setState({
        secteursActivite: args.value,
        categorieActivite: [],
      });
    }
  }
  handleDate(args) {
    if (args !== null) {
      this.setState({
        dateDebut: { date: args[0].$d, change: true },
        dateFin: { date: args[1].$d, change: true },
      });
    } else
      this.setState({
        dateDebut: { date: new Date(), change: false },
        dateFin: { date: new Date(), change: false },
      });
  }
  actionsFormatter = (cell, row) => (
    <React.Fragment>
      {!isEmpty(row.contactId) ? (
        <Link
          to={{
            pathname: `/contact?name=${row.contactId._id}`,
          }}
        >
          <PermContactCalendarIcon sx={{ fontSize: 40 }} />
        </Link>
      ) : (
        <DialerSipIcon sx={{ fontSize: 40 }} />
      )}
    </React.Fragment>
  );
  async handleDataChange({ dataSize }) {
    this.setState({ rowCount: dataSize });
  }

  render() {
    let userList = this.props.auth.listUser
      .filter((el) => el.actif === true && el.post > 0)
      .map((item) => {
        return { text: item.username, id: item._id };
      }, this);

    const rankFormatter = (cell, row, rowIndex, formatExtraData) => {
      if (!("dateDebut" in row) || !("dateFin" in row))
        return <FiPhoneMissed color="red" />;
      if (formatExtraData[cell] === 0) return <FiPhoneOutgoing color="green" />;
      if (formatExtraData[cell] === 1) return <FiPhoneIncoming color="blue" />;
    };

    const nameFormatter = (cell, row, rowIndex, formatExtraData) => {
      if (!isEmpty(row.contactId)) {
        return row.contactId.firstname + " " + row.contactId.lastname;
      } else return "";
    };

    const formatDate = (string) => {
      var options = { year: "numeric", month: "numeric", day: "numeric" };
      return new Date(string).toLocaleDateString([], options);
    };

    const msToTime = (duration) => {
      if (isNaN(duration)) return "--:--";
      var seconds = Math.floor((duration / 1000) % 60),
        minutes = Math.floor((duration / (1000 * 60)) % 60),
        hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

      hours = hours < 10 ? "0" + hours : hours;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      seconds = seconds < 10 ? "0" + seconds : seconds;

      return hours + ":" + minutes + ":" + seconds;
    };

    const rowStyle = (row, rowIndex) => {
      const style = {};
      if (!isEmpty(row.contactId)) {
        if (row.contactId.typeContact === "Business") {
          if (
            row.contactId.secteur === "" ||
            row.contactId.categorieActivite === ""
          ) {
            style.backgroundColor = "#7895B2";
            style.color = "white";
            style.fontWeight = "bold";
          }
        }
      } else {
        style.backgroundColor = "#E14D2A";
        style.color = "white";
        style.fontWeight = "bold";
      }

      return style;
    };

    const rowStyle2 = (row, rowIndex) => {
      const style = {};
     
        if (row.ratio10 > 50 && row.ratio10 < 50) {
          style.backgroundColor = "#f07810";
          style.color = "white";
          style.fontWeight = "bold";
        }
       else  
        if (row.ratioStatut > 50 && row.ratio10 < 50) {
          style.backgroundColor = "#e6e696";
          style.color = "gray";
          style.fontWeight = "bold";
        }
      else if
         (row.ratioStatut > 50 && row.ratio10 > 50) {
          style.backgroundColor = "#eb2632";
          style.color = "white";
          style.fontWeight = "bold";
        }
      
      // else {
      //   style.backgroundColor = "#E14D2A";
      //   style.color = "white";
      //   style.fontWeight = "bold";
      // }

      return style;

    };
    const dureeFormatter = (duree) => {
      return msToTime(parseInt(duree));
    };
    const ratioFormatter = (string) => {
      return !isEmpty(string) ? `${string} %` : "0 %";
    };

    const dureeMoyFormatter = (duree) => {
      return !isEmpty(duree) ? msToTime(parseInt(duree)) : "00:00:00";
    };

    /// WWW

    //  console.log("this.props.data.callsForDisplay.liste",this.props.data.callsForDisplay.listtotCallsOp)

    const columns = [
      {
        dataField: "direction",
        text: " ",
        align: "center",
        sort: true,
        formatter: rankFormatter,
        formatExtraData: {
          out: 0,
          in: 1,
        },
      },

      {
        dataField: "caller.username",
        text: "Opérateur",
        filter: textFilter(),
        sort: true,
      },
      {
        text: "Contact",
        dataField: "contactId",
        sort: true,
        filter: textFilter(),
        formatter: nameFormatter,
      },

      {
        text: "Compagnie",
        dataField: "contactId.nomCompagnie",
        filter: textFilter(),
        sort: true,
      },
      {
        text: "Cat.",
        dataField: "contactId.categorieActivite",
        filter: textFilter(),
        sort: true,
      },
      {
        text: "Sect.",
        dataField: "contactId.secteur",
        filter: textFilter(),
        sort: true,
      },
      {
        text: "Numéro",
        dataField: "numero.number",
        filter: textFilter(),
        sort: true,
      },

      // {
      //   text: "Heure Debut",
      //   dataField: "dateDebut",
      //   formatter: formatHour,
      //   sort: true,
      // },
      // {
      //   text: "Heure Fin",
      //   dataField: "dateFin",
      //   formatter: formatHour,
      //   sort: true,
      // },
      {
        text: "Durée",
        dataField: "duree",
        sort: true,
        filter: textFilter(),
        formatter: msToTime,
      },
      {
        text: "Statut",
        dataField: "statutAppel",
        filter: textFilter(),
        sort: true,
      },
    ];
    const columns2 = [
      {
        dataField: "direction",
        text: " ",
        align: "center",
        sort: true,
        // formatter: rankFormatter,
        // formatExtraData: {
        //   out: 0,
        //   in: 1,
        // },
      },

      {
        text: "Opérateur",
        dataField: "userName",
        filter: textFilter(),
        sort: true,
      },
      {
        text: "Tous les appels",
        dataField: "nbrAppelTot",
        sort: true,
      },
      {
        text: "Appels manqués",
        dataField: "nbrAppelManque",
        sort: true,
      },
      {
        text: "Appels succées",
        dataField: "nbrAppelSucces",
        sort: true,
      },

      {
        text: "Durée tot",
        dataField: "dureeTotal",
        formatter: dureeFormatter,
        sort: true,
      },
      {
        text: "Durée moy",
        dataField: "dureeMoy",
        formatter: dureeMoyFormatter,
        sort: true,
      },
      {
        text: "Ratio Inj (<= 10s)",
        dataField: "ratio10",
        formatter: ratioFormatter,
        sort: true,
      },
      {
        text: "Ratio Inj (statut)",
        dataField: "ratioStatut",
        formatter: ratioFormatter,
        sort: true,
      },
    ];

    const sizePerPageRenderer = ({
      options,
      currSizePerPage,
      onSizePerPageChange,
    }) => (
      <div className="btn-group" role="group">
        {options.map((option) => {
          const isSelect = currSizePerPage === `${option.page}`;
          return (
            <button
              key={option.text}
              type="button"
              onClick={() => onSizePerPageChange(option.page)}
              className={`btn ${isSelect ? "btn-secondary" : "btn-warning"}`}
            >
              {option.text}
            </button>
          );
        })}
      </div>
    );

    const options = {
      sizePerPageRenderer,
    };

    const config = {
      data: this.props.data.callsForDisplay.liste.reverse(),
      isStack: true,
     // xField: "nbrAppel",
      xField: "userName",
    //  yField: "userName",
      yField: "nbrAppel",
      seriesField: "type",
      label: {
        position: "top",

        layout: [
          {
            type: "interval-adjust-position",
          },
          {
            type: "interval-hide-overlap",
          },
          {
            type: "adjust-color",
          },
        ],
      },
    };
    return (
      <div>
        <Row>
          <Divider>
            <React.Fragment>
              {"nbreAppelSucces" in this.props.data.callsForDisplay ||
              "nbreAppelNonSucces" in this.props.data.callsForDisplay ? (
                <React.Fragment>
                  <Tag color="#108ee9">Nbr d'appel Total</Tag>
                  <Tag color="blue">
                    {" "}
                    {!isNaN(
                      this.props.data.callsForDisplay.nbreAppelSucces +
                        this.props.data.callsForDisplay.nbreAppelNonSucces
                    )
                      ? (
                          this.props.data.callsForDisplay.nbreAppelSucces +
                          this.props.data.callsForDisplay.nbreAppelNonSucces
                        ).toString()
                      : null}
                  </Tag>
                </React.Fragment>
              ) : null}
              {"nbreAppelSucces" in this.props.data.callsForDisplay ? (
                <React.Fragment>
                  <Tag color="#90B77D">Nbr d'appel succes</Tag>
                  <Tag color="success">
                    {this.props.data.callsForDisplay.nbreAppelSucces}
                  </Tag>
                </React.Fragment>
              ) : null}
              {"nbreAppelNonSucces" in this.props.data.callsForDisplay ? (
                <React.Fragment>
                  <Tag color="#EB1D36">Nbr d'appel manque</Tag>
                  <Tag color="red">
                    {this.props.data.callsForDisplay.nbreAppelNonSucces}
                  </Tag>
                </React.Fragment>
              ) : null}
              {"ratio" in this.props.data.callsForDisplay ? (
                <React.Fragment>
                  <Tag color="#9E7676">Ratio Injoignable {"<= 10s"}</Tag>
                  <Tag color="volcano">
                    {this.props.data.callsForDisplay.ratio} %
                  </Tag>
                </React.Fragment>
              ) : null}
              {this.props.data.callsForDisplay.dureeTotal &&
              this.props.data.callsForDisplay.nbreAppelSucces ? (
                <React.Fragment>
                  <Tag color="#FFAE6D">Durée moy d'appel</Tag>
                  <Tag color="gold">
                    {msToTime(
                      parseInt(
                        this.props.data.callsForDisplay.dureeTotal /
                          this.props.data.callsForDisplay.nbreAppelSucces
                      )
                    )}
                  </Tag>
                </React.Fragment>
              ) : null}
              {this.props.data.callsForDisplay.dureeTotal ? (
                <React.Fragment>
                  <Tag color="#f5587b">Durée tot d'appel</Tag>
                  <Tag color="magenta">
                    {msToTime(
                      parseInt(this.props.data.callsForDisplay.dureeTotal)
                    )}
                  </Tag>
                </React.Fragment>
              ) : null}
            </React.Fragment>
          </Divider>{" "}
        </Row>   
        <Paper elevation={2}>
          <Bar {...config} />
        </Paper>
        <br />
        <br />
        {/* Nouveau ajout WWW */}
        <Paper elevation={2}>
          {" "}
          <br />
          <ToolkitProvider
            keyField="_id"
            data={this.props.data.callsForDisplay.listtotCallsOp}
            search
            columns={columns2}
          >
            {(props) => (
              <div>
  
                  <div align="center">
                  <Tag color="#f07810">
                    Ratio inj (10s) superieur à 50%
                  </Tag>
                  <Tag color="#e6e696">Ratio inj (statut) superieur à 50%</Tag>
                  <Tag color="#eb2632">Ratio inj ( 10s et statut) superieur à 50%</Tag>
                </div>
                <br/>
                <BootstrapTable
                  {...props.baseProps}
                  // pagination={paginationFactory(options)}
                  filter={filterFactory()}
                  filterPosition="top"
                  condensed
                  noDataIndication="Pas d'appels"
                  // onDataSizeChange={this.handleDataChange}
                  rowStyle={rowStyle2}
                  bordered={false}
                  striped
                />
              </div>
            )}
          </ToolkitProvider>
          {/* </Col> */}
        </Paper>
        {/* Nouveau ajout */}
        <br />
        <br />

        <Paper elevation={2}>
          {" "}
          {/* <Col span={14}> */} <br />
          <ToolkitProvider
            keyField="_id"
            data={this.props.data.callsForDisplay.call
              .filter((s) => {
                if (this.state.statutAppel.length > 0) {
                  if (this.state.statutAppel.includes(s.statutAppel)) return s;
                } else return true;
              })
              .filter((t) => {
                if (
                  compareTime(formatHour(t.dateDebut), this.state.heureDeb) <
                    0 &&
                  compareTime(formatHour(t.dateFin), this.state.heureFin) > 0
                )
                  return t;
              })
              .filter((f) => {
                if (this.state.categorieActivite.length > 0) {
                  if (
                    this.state.categorieActivite.includes(
                      f.contactId ? f.contactId.categorieActivite : true
                    )
                  )
                    return f;
                } else return true;
              })
              .filter((e) => {
                if (this.state.secteursActivite.length > 0) {
                  if (
                    this.state.secteursActivite.includes(
                      e.contactId ? e.contactId.secteur : true
                    )
                  )
                    return e;
                } else return true;
              })}
            search
            columns={columns}
          >
            {(props) => (
              <div>
                <div align="center">
                  <Tag color="#7895B2">
                    Contact commercial avec secteur ou catégorie d'activité non
                    précisé (e) (s)
                  </Tag>
                  <Tag color="#E14D2A">Appel direct via TSIP !!</Tag>
                </div>

                <BootstrapTable
                  {...props.baseProps}
                  pagination={paginationFactory(options)}
                  filter={filterFactory()}
                  filterPosition="top"
                  condensed
                  noDataIndication="Pas d'appels"
                  onDataSizeChange={this.handleDataChange}
                  rowStyle={rowStyle}
                  bordered={false}
                  striped
                />
              </div>
            )}
          </ToolkitProvider>
          {/* </Col> */}
        </Paper>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  data: state.data,
  contact: state.contact,
});

export default connect(mapStateToProps, {
  searchContactFiche,
  getCallsForDisplay,
})(TvDisplay);

import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import React, { useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import setAuthToken from "./utils/setAuthToken";
import { setCurrentUser, logoutUser } from "./actions/authActions";
import { Provider } from "react-redux";
import store from "./store";
import "./App.scss";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import fr from "date-fns/locale/fr"; // Locale française
import Login from "./components/auth/Login";
import TvDisplay from "./components/Dashboard/TvDisplay";
import TvDisplay2 from "./components/Dashboard/TvDisplay2";
import TvDisplay3 from "./components/Dashboard/TvDisplay3";
import TvDisplay4 from "./components/Dashboard/TvDisplay4";
import PrivateRoute from "./components/private-route/PrivateRoute";
import 'antd/dist/reset.css';
import axios from "axios";

//axios.defaults.baseURL = 'http://localhost:5000/'; 
axios.defaults.baseURL = 'https://preprod.solutionsexpressqc.ca/';  //EN LIGNE

const DefaultLayout = React.lazy(() =>
  import("./components/layout/DefaultLayout")
);

// Vérifier le token pour garder l'utilisateur connecté
if (localStorage.jwtToken) {
  // Définir le token d'authentification
  const token = localStorage.jwtToken;
  setAuthToken(token);
  // Décoder le token et obtenir les infos de l'utilisateur
  const decoded = jwtDecode(token);
  // Définir l'utilisateur et son état d'authentification
  store.dispatch(setCurrentUser(decoded));
  // Vérifier si le token a expiré
  const currentTime = Date.now() / 1000; // pour obtenir en millisecondes
  if (decoded.exp < currentTime) {
    // Déconnecter l'utilisateur
    store.dispatch(logoutUser());
    // Rediriger vers la page de connexion
    window.location.href = "./login";
  }
}

const loading = () => (
  <div className="animated fadeIn pt-3 text-center">Loading...</div>
);

const App = () => {
  useEffect(() => {
    function handleAdd(e) {
      if (e.key === "jwtToken" && e.oldValue && !e.newValue) {
        store.dispatch(logoutUser());
      }
    }
    window.addEventListener("storage", handleAdd);
    return () => {
      window.removeEventListener("storage", handleAdd, false);
    };
  }, []);

  return (
    <Provider store={store}>
      <Router>
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={fr}>
        <React.Suspense fallback={loading()}>
            {window.location.pathname === "/tvDisplay" ? (
              <Route exact path="/tvDisplay" component={TvDisplay} />
            )
            :
            window.location.pathname === "/tvDisplay2" ? (
              <Route exact path="/tvDisplay2" component={TvDisplay2} />
            )
            :
            window.location.pathname === "/tvDisplay3" ? (
              <Route exact path="/tvDisplay3" component={TvDisplay3} />
            )
            
            :
            
            window.location.pathname === "/TvDisplay4" ? 
            
            (
              <Route exact path="/TvDisplay4" component={TvDisplay4} />
            )
            :
            (
              <React.Fragment>
                <Route exact path="/login" component={Login} />
                <Switch>
                  <PrivateRoute
                    name="Home"
                    path="/"
                    component={DefaultLayout}
                  />
                </Switch>
              </React.Fragment>
            )}
          </React.Suspense>
        </LocalizationProvider>
      </Router>
    </Provider>
  );
};

export default App;
